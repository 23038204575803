.content-dashboard {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 50px 1fr 1fr 1fr;
  gap: 24px;

  & > div {
    padding: 24px;
    box-shadow: -7px -2px 15px rgba(0,0,0,.05), 5px 5px 15px rgba(0,0,0,.05);
    background: #fff;
  }

  &__title {
    grid-column: 1 / span 4;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;

    & span {
      user-select: none;
    }
  }

  &__charts {
    grid-column: 1 / span 3;
    grid-row: 2 / span 2;
    padding: 10px;

    &--centered {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__info {
    display: flex;
    align-items: center;

    .anticon {
      font-size: 54px;
      padding-right: 10px;
    }

  }

  .info-block {
    user-select: none;

    &__title {
      font-size: 18px;
    }

    &__value {
      font-size: 24px;
      line-height: 30px;
    }
  }
}
