@import "~antd/dist/antd.css";

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
  display: flex;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 1;
}

.logo {
  padding: 8px 24px;
}

.logo img {
  width: 170px;
}

.site-layout {
  min-height: 100vh;
}

.site-layout-background {
  background: #fff !important;
}

.ant-menu-item,
.ant-menu-submenu-title {
  user-select: none;
}

.ant-layout-sider {
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
}

.ant-layout-content {
  margin: 24px;
}
