.charts {
  position: relative;
  min-height: 99%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100%;

  &__chart {
    padding: 15px 5px;
    min-width: 100%;
  }

  &__metrics {
    padding: 5px 15px 5px 0;
    display: flex;

  }

  &--dashboard {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    height: 100%;
    min-height: 100%;

    .charts__chart {
      padding: 0 5px;
    }

    .charts__chart, .charts__metrics {
      width: 100%;
    }

    .metrics {
      width: 100%;
    }

    .metrics__header {
      display: none;
    }

    .metrics__body {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
    }

  }
}

.chartjs-tooltip {
  transition: all .5s ease-out;
  min-width: 180px;
  font-family: Roboto, serif !important;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  box-shadow: 5px 5px 15px rgba(0,0,0,.15), -7px -2px 15px rgba(0,0,0,.1);
  font-size: 13px !important;
  top: 50%;
  padding: 0 !important;
  z-index: 6;

  &__header {
    font-weight: bold;
    padding: 5px 8px 2px;
  }

  &__line {
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    line-height: 23px;
    padding: 0 8px;

    &:not(:last-child) {
      border-bottom: 1px solid #F2F2F8;
    }
  }

  &__marker {
    display: inline-block;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%;
    font-weight: 300;
  }
}

.metrics {
  display: flex;
  flex-direction: column;

  &__header {
    color: #9AA0B9;
    text-transform: uppercase;
    height: 24px;
    line-height: 24px;
    margin-bottom: 5px;
  }

  &__body {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    grid-auto-rows: minmax(min-content, max-content);

    & > * {
      display: inline-flex;
      height: 24px;
      line-height: 24px;
      // width: 100%;
    }
  }
}
