.table-report {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr;
  gap: 24px;

  & > div {
    padding: 24px;
    box-shadow: -7px -2px 15px rgba(0,0,0,.05), 5px 5px 15px rgba(0,0,0,.05);
    background: #fff;
  }

  &__title {
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;

    & span {
      user-select: none;
    }
  }
}
